import React from "react"
import { singlePageHelper } from "../utils/singlePageHelper"
import SinglePageLayoutGrid from "../layouts/singlePageGridLayout"
import { Helmet } from "react-helmet"
function articleSinglePage({ pageContext }) {
  const articles = pageContext.relatedArticles
  const tagSets = pageContext.tagSets
  const [singlePage, originalSource, source] = singlePageHelper({ pageContext })

  return (
    <>
      <Helmet>
        <title>Cassandra Link - {singlePage.title}</title>
        <meta name="description" content={singlePage.description} />
        <meta property={singlePage.description} />
        <meta property="og:description" content={singlePage.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={singlePage.link} />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={singlePage.title} />
        <meta name="twitter:description" content={singlePage.description} />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra Link",
            keywords: "Cassandra Link",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        <meta property="og:title" content={singlePage.title} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <SinglePageLayoutGrid
        args={{
          singlePage,
          source,
          articles,
          tagSets,
        }}
      />
    </>
  )
}

export default articleSinglePage

import React from "react"

import { Typography } from "@mui/material"

export function singlePageHelper({ pageContext }) {
  let singlePage, source, domain, linkToPage, originalSource
  //====================================================/
  //Picks single article from list
  singlePage = pageContext.node
  //====================================================/
  //Checks if link is oembed
  source = singlePage.url
  domain = singlePage.domain_name
  linkToPage = domain === "www.youtube.com" ? source.split("url=")[1] : source
  //====================================================/
  //Checks if page includes video
  originalSource =
    domain === "www.youtube.com" ? null : (
      <Typography className="dont-break-out">
        Originally sourced from <a href={singlePage.url}> {singlePage.url} </a>
      </Typography>
    )

  return [singlePage, originalSource, source]
}

import React from "react"
import { Container, Grid } from "@mui/material"
import Layout from "../components/Layout/Layout"
import DescriptionCard from "../components/ArticleSinglePage/Cards/DescriptionCard"
import NotificationComponent from "../components/ArticleSinglePage/Cards/NotificationComponent"
import ArticleContent from "../components/ArticleSinglePage/ArticleContent"
import RelatedArticlesLayout from "./RelatedArticlesLayout"
import TrainingAdComponent from "../components/ArticleSinglePage/Cards/TrainingAdComponent"
import NewsLetterCard from "../components/ArticleSinglePage/Cards/NewsLetterCard"
import ExploreRelatedTopics from "./ExploreRelatedTopicsLayout"
import ExploreFurther from "./ExploreFurtherLayout"
import ThumbnailImage from "../components/ArticleSinglePage/Cards/Thumbnail/Thumbnail"
function SinglePageLayoutGrid({
  args: { singlePage, source, articles, tagSets },
}) {
  return (
    <Layout>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            sx={{ borderBottom: "1px solid black", marginBottom: 5 }}
            container
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <DescriptionCard article={singlePage} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThumbnailImage url={singlePage.preview_picture} />
              <NotificationComponent
                args={{
                  notificationTitle: singlePage.title,
                  articleUrl: source,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <ArticleContent content={singlePage.content} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RelatedArticlesLayout data={articles} />
                </Grid>
                <Grid item xs={12}>
                  <TrainingAdComponent buttonLink={"/training-cassandra"} />
                </Grid>
                <Grid item xs={12}>
                  <NewsLetterCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ExploreRelatedTopics />
          </Grid>
          <Grid sx={{ marginBottom: 4 }} item xs={12}>
            <ExploreFurther
              args={{
                data: tagSets,
                isListingPage: false,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {/* comments section */}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default SinglePageLayoutGrid

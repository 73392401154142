import React from "react"
import { Grid } from "@mui/material"
import "./resource-single.scss"
function ArticleContent({ content }) {
  return (
    <Grid
      className="articleContainer"
      dangerouslySetInnerHTML={{
        __html: `<article> ${content} </article>`,
      }}
    />
  )
}

export default ArticleContent

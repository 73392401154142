import { Box } from "@mui/material"
import React from "react"
import NoImg from "../../../../images/gallery/NoPreviewImage.png"
const ThumbnailImage = ({ url, imageHeight }) => {
  return (
    <Box
      sx={{
        marginTop: 1,
        width: "100%",
        height: imageHeight ? imageHeight : { sm: "250px", md: "600px" },
      }}
    >
      {/* Static image is not working so we must use img tag */}
      <img
        width={"100%"}
        height={"100%"}
        src={url ? url : NoImg}
        alt="Illustration Image"
      />
    </Box>
  )
}

export default ThumbnailImage

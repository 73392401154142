import { Grid, Box, Typography } from "@mui/material"
import React from "react"
import { GiLightBulb } from "react-icons/gi"
const NotificationComponent = ({ args: { notificationTitle, articleUrl } }) => {
  return (
    <Grid container sx={{ marginY: 2 }}>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "#F3F3F3",
            paddingX: 2,
            paddingY: 1.5,
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              fontSize: { sm: "18px", md: "28px" },
              marginRight: 1.5,
            }}
          >
            <GiLightBulb style={{ color: "#FFA62B", display: "block" }} />
          </Box>
          <a href={articleUrl} style={{ textDecoration: "none" }}>
            <Typography
              fontFamily="Roboto condensed, sans-serif"
              className="textTruncate-2"
              sx={{
                color: "#163BBF",
                lineHeight: { xs: "15px", md: "24px" },
                fontSize: { xs: "13px", sm: "11px", md: "20px" },
              }}
            >
              Read this article if you want to know more about{" "}
              {notificationTitle}
            </Typography>
          </a>
        </Box>
      </Grid>
    </Grid>
  )
}

export default NotificationComponent
